import React from "react";
import Layout from "src/components/Layout";
import HeadSEO from "src/components/HeadSEO";
import { Box, Heading, Text, VStack, Divider } from "@chakra-ui/react";
import Container from "src/components/Container";
import TheRacesBanner from "src/components/banners/TheRacesBanner";
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";

const getData = graphql`
  {
    markdownRemark(fields: { slug: { eq: "/pages/the-races/" } }) {
      frontmatter {
        banner_text
        section_one
        races {
          race_name
          race_description
        }
        section_three
      }
    }
  }
`;

const TheRaces = () => {
  const data = useStaticQuery(getData);
  const page = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <HeadSEO
        title="National Penny Farthing Championships | Evandale Village Fair"
        description="Since 1983 the Evandale Village Fair and National Penny Farthing
              Championships has built up a reputation for holding the most
              competitive Penny Farthing races in the world."
        slug="/the-races/"
      />
      <TheRacesBanner
        overlayOpacity="0.75"
        overlay="#223843"
        backgroundPosition="50% 40%"
      >
        <Container textAlign="center" size="sm" color="white">
          <VStack spacing="1.5rem">
            <Heading as="h1" size="2xl">
              {page.banner_text}
            </Heading>
          </VStack>
        </Container>
      </TheRacesBanner>
      <Box as="section">
        <Container size="md" py="6rem">
          <VStack align="flex-start" spacing="2rem">
            <ReactMarkdown children={page.section_one} />
          </VStack>
        </Container>
      </Box>
      <Box as="section" color="white" bg="teal.700">
        <Container size="md" py="6rem">
          <Heading size="xl">Events on The Day</Heading>
          <VStack mt="2rem" align="flex-start" spacing="1.5rem">
            {page.races.map((race) => {
              return (
                <>
                  <Divider borderColor="orange.300" borderBottomWidth="2px" />
                  <VStack align="flex-start" spacing="0.25rem">
                    <Heading as="h3" size="md" fontFamily="body">
                      {race.race_name}
                    </Heading>
                    <Text>{race.race_description}</Text>
                  </VStack>
                </>
              );
            })}
          </VStack>
        </Container>
      </Box>
      <Box as="section">
        <Container size="md" py="6rem">
          <VStack align="flex-start" spacing="2rem">
            <ReactMarkdown children={page.section_three} />
          </VStack>
        </Container>
      </Box>
    </Layout>
  );
};

export default TheRaces;
